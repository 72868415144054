html,
body,
#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scrollbar-width: none; */
  background: #f5f5f5;
}

/* ::-webkit-scrollbar {
  display: none;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-line-clamp {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@supports (-webkit-line-clamp: 2) {
  .text-line-clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
