.custom-toast {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  width: 320px;
  min-height: 76px;
}

.custom-toast .Toastify__close-button {
  opacity: 0.8;
}

.custom-toast .Toastify__toast-icon {
  align-self: flex-start;
  margin-top: 2px;
}

.custom-toast .Toastify__close-button > svg {
  height: 18px;
  width: 18px;
}

.custom-toast.success {
  background: #e6f6ea;
  color: #00621c;
}

.custom-toast.warning {
  background: #fff7e7;
  color: #97670a;
}

.custom-toast.error {
  background: #f6e6e6;
  color: #a40000;
}

.custom-toast.success svg {
  fill: #00a32e;
}

.custom-toast.warning svg {
  fill: #97670a;
}

.custom-toast.error svg {
  fill: #a40000;
}
